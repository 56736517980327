import firebase from "firebase/app"
import "firebase/firestore"

class Firebase {
	constructor() {
		this.initFirebase()
	}

	db: any

	initFirebase() {
		if (firebase.apps.length) return
		const config = {
			// apiKey: "AIzaSyC77IMSzncR28nKqg4uZaeZGSO4ClzX_Ps",
			// authDomain: "holy-kalo.firebaseapp.com",
			// databaseURL: "https://holy-kalo.firebaseio.com",
			projectId: "holy-kalo"
			// storageBucket: "holy-kalo.appspot.com",
			// messagingSenderId: "90507705263"
		}
		firebase.initializeApp(config)
		this.db = firebase.firestore()
		const settings = { timestampsInSnapshots: true }
		this.db.settings(settings)
	}

	get(table: string) {
		return new Promise((resolve, reject) => {
			this.db &&
				this.db
					.collection(table)
					.get()
					.then(
						querySnapshot => {
							const list = []
							querySnapshot.forEach(element => {
								const data = element.data()
								list.push(data)
							})
							resolve(list)
						},
						(error: any) => {
							console.log(error)
							reject(error)
						}
					)
		})
	}
}

export default Firebase
