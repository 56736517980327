import Firebase from "./utils/firebase";
import { Speaker } from "./utils/interfaces";
import Glide from "@glidejs/glide";
import TweenLite from "gsap/TweenLite";
import TimelineLite from "gsap/TimelineLite";
import "gsap/CSSPlugin";
import ScrollMagic from "scrollmagic";
import "scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap";

const firebase = new Firebase();

/* ===============================================
   ================= speakers ==================
=============================================== */

const speakersContainer = document.querySelector("#speakers");

loadSpeakers();

function loadSpeakers() {
	firebase.get("speakers").then((data: Array<Speaker>) => {
		if (data && data.length) {
			renderSpeakers(data);
		} else {
			hideSpeakers();
		}
	});
}

function hideSpeakers() {
	const speakersSection: HTMLElement = document.querySelector(".speakers");
	speakersSection.style.display = "none";
}

function renderSpeakers(speakers: Array<Speaker>) {
	let speakersNode = "";
	for (let speaker of speakers) {
		speakersNode += speakerToNode(speaker);
	}
	speakersContainer.innerHTML = speakersNode;

	initializeSlider();
}

function speakerToNode(speaker: Speaker) {
	let markup = speakerMarkup;
	markup = markup.replace(/{{name}}/g, speaker.name || "");
	markup = markup.replace(
		/{{image}}/g,
		speaker.photos.length ? speaker.photos[0].url : ""
	);
	markup = markup.replace(/{{notes}}/g, speaker.notes || "");
	return markup;
}

const speakerMarkup = `
  <div class="glide__slide">
    <img class="speaker__image" width="170" height="170" src="{{image}}" alt="{{name}}">
    <div class="speaker__name">{{name}}</div>
    <div class="speaker__title">{{notes}}</div>
  </div>
`;

/*=============================================
=            Animation     	          =
=============================================*/

const controller = new ScrollMagic.Controller();

/*----------  ESPA ANIMATION  ----------*/
const espa: HTMLElement = document.querySelector(".espa");
const espaTween = TweenLite.to(espa, 1, { opacity: 0 });
const espaScene = new ScrollMagic.Scene({
	triggerElement: "header",
	triggerHook: "onLeave",
	duration: "5%"
})
	.setTween(espaTween)
	.addTo(controller);

/*----------  PEOPLE ANIMATION  ----------*/

const persons: NodeListOf<HTMLElement> = document.querySelectorAll(
	".person__icon"
);
let personsAttributes = [];
function setPersonsAttributes() {
	personsAttributes = [];
	persons.forEach((person: HTMLElement, index: number) => {
		let windowInnerWidth = window.innerWidth;
		let personWidth = person.getBoundingClientRect().width;
		let personLeft = person.getBoundingClientRect().left;
		if (person.dataset.side === "left") {
			let personMoveLeft = (personLeft + personWidth) * 3;
			personsAttributes.push({ index, person, moveBy: -personMoveLeft });
		} else if (person.dataset.side === "right") {
			let personMoveRight = (windowInnerWidth - personLeft) * 3;
			personsAttributes.push({ index, person, moveBy: personMoveRight });
		}
	});
}
setPersonsAttributes();
window.addEventListener("resize", setPersonsAttributes);

const peopleTween = new TimelineLite();
// persons.forEach((person: HTMLElement, index: number) => {
// 	let windowInnerWidth = window.innerWidth
// 	let personWidth = person.getBoundingClientRect().width
// 	let personLeft = person.getBoundingClientRect().left
// 	if (person.dataset.side === "left") {
// 		let personMoveLeft = (personLeft + personWidth) * 2
// 		peopleTween.add(TweenLite.to(person, 1, { x: -personMoveLeft }, 0))
// 	} else if (person.dataset.side === "right") {
// 		let personMoveRight = (windowInnerWidth - personLeft) * 2
// 		peopleTween.add(TweenLite.to(person, 1, { x: personMoveRight }, 0))
// 	}
// })
// console.log(peopleTween)
peopleTween
	.to(personsAttributes[0].person, 1, { x: personsAttributes[0].moveBy })
	.to(personsAttributes[1].person, 1, { x: personsAttributes[1].moveBy }, 0)
	.to(personsAttributes[2].person, 1, { x: personsAttributes[2].moveBy }, 0)
	.to(personsAttributes[3].person, 1, { x: personsAttributes[3].moveBy }, 0)
	.to(personsAttributes[4].person, 1, { x: personsAttributes[4].moveBy }, 0)
	.to(personsAttributes[5].person, 1, { x: personsAttributes[5].moveBy }, 0)
	.to(personsAttributes[6].person, 1, { x: personsAttributes[6].moveBy }, 0)
	.to(personsAttributes[7].person, 1, { x: personsAttributes[7].moveBy }, 0);

const peopleScene = new ScrollMagic.Scene({
	triggerElement: "header",
	triggerHook: "onLeave",
	duration: 500
})
	.setTween(peopleTween)
	.addTo(controller);

/*----------  Sectors animation  ----------*/

const sectors = document.querySelector(".sectors");
const sectorTween = new TweenLite.to(sectors, 1, { opacity: 1 });
const sectorsScene = new ScrollMagic.Scene({
	triggerElement: "header",
	triggerHook: "onLeave",
	duration: "10%"
})
	.setTween(sectorTween)
	.addTo(controller);

/*=====  End of Animation  ======*/

/*=============================================
=            Speakers Slider                  =
=============================================*/

function initializeSlider() {
	var speakersSlider = new Glide(".glide", {
		type: "slider",
		gap: 16,
		perView: 6,
		focusAt: 0,
		rewind: true,
		animationTimingFunc: "ease-in-out",
		peek: {
			before: 100,
			after: 100
		},
		bound: true,
		breakpoints: {
			1366: {
				perView: 4
			},
			1024: {
				perView: 3,
				peek: {
					before: 50,
					after: 50
				}
			},
			550: {
				perView: 2,
				peek: {
					before: 50,
					after: 50
				}
			},
			370: {
				perView: 1,
				peek: {
					before: 50,
					after: 50
				}
			}
		}
	});
	speakersSlider.mount();
}

/*=====  End of Speakers Slider  ======*/
